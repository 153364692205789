import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { getDueDateForInvoice, isEnableBed } from '@/auth/utils';
import releaseInvoiceStoreModule from '../releaseInvoiceStoreModule';
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useReleaseInvoiceList() {
  const STORE_MODULE_NAME = 'releaseInvoice';

  const { t } = useI18nUtils()

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, releaseInvoiceStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const dueDate = getDueDateForInvoice();

  const blankItem = {
    id: 0,
    month: moment(new Date()).format("MM-YYYY"),
    name: '',
    apartment: null,
    room: null,
    bed: null,
    contract: null,
    issueDate: moment(new Date()).toDate(),
    paymentPeriod: null,
    finalDate: null,
    dueDate,
    note: '',
    items: [],
    subTotal: 0,
    discount: 0,
    promotion: 0,
    prepaid: 0,
    tax: 0,
    total: 0,
    typeObject: { title: 'Hóa đơn hàng tháng', id: '1', value: '1' },
    type: '1',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'releaseInvoice') && !vm.$can('delete', 'releaseInvoice') && !vm.$can('read', 'releaseInvoice');

  // Table Handlers
  const columns = [
    {
      label: 'Mã hóa đơn',
      field: 'invoiceNo',
      sortable: false,
    },
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      hidden: false,
      width: '100px',
    },
    {
      label: t('Cơ sở'),
      field: 'school.name',
    },
    {
      label: 'Tháng',
      field: 'month',
    },
    {
      label: t('Lớp học'),
      field: 'class.name',
    },
    {
      label: t('Học sinh'),
      field: 'student.name',
    },
    {
      label: t('Tổng trước thuế'),
      field: 'subTotal',
      type: 'number',
    },
    {
      label: t('Thuế'),
      field: 'taxValue',
      type: 'number',
    },
    {
      label: t('Tổng sau thuế'),
      field: 'total',
      type: 'number',
    },
    {
      label: t('Ngày xuất'),
      field: 'issueDate',
      sortable: false,
    },
    {
      label: t('Mã CQT'),
      field: 'codeOfTax',
      sortable: false,
    },
    {
      label: t('Thanh toán'),
      field: 'paymentStatus',
      sortable: false,
    },
    {
      label: t('Trạng thái'),
      field: 'adjustmentType',
      sortable: false,
    }
  ];

  const paymentItem = ref({});
  const rows = ref([]);
  const analytics = ref(null);
  // filter
  const paymentPeriod = ref(null)
  const school = ref(null);
  const classObject = ref(null);
  const student = ref(null);
  const status = ref(null);
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const month = ref(null);
  const approveStatus = ref(null);
  const paymentStatus = ref(null);
  const dueDateStatus = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {

    },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchReleaseInvoice = () => {
    isLoading.value = false;
    store
      .dispatch('releaseInvoice/fetchReleaseInvoice', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('releaseInvoice/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchReleaseInvoice();
  };

  const deleteReleaseInvoices = invoices => {
    store
      .dispatch('releaseInvoice/deleteReleaseInvoice', {
        ids: invoices.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('invoice/importInvoices', formData)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('releaseInvoice/exportReleaseInvoices', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = data => {
    let filter = {};
    if (data && data.school && data.school.id) {
      filter = {
        ...filter,
        schoolId: data.school.id,
      };
    }
    if (data && data.class && data.class.id) {
      filter = {
        ...filter,
        classId: data.class.id,
      };
    }
    store
      .dispatch('invoice/generateImportTemplate', { filter })
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  watch(school, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.schoolId = val.id;
    } else {
      delete filter.schoolId;
    }
    updateParams({ filter });
  });
  watch(classObject, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.classId = val.id;
    } else {
      delete filter.classId;
    }
    updateParams({ filter });
  });
  watch(student, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.studentId = val.id;
    } else {
      delete filter.studentId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });
  watch(approveStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.approve = val.value;
    } else {
      delete filter.approve;
    }
    updateParams({ filter });
  });
  watch(paymentStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.id;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(dueDateStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.dueDateStatus = val.id;
    } else {
      delete filter.dueDateStatus;
    }
    updateParams({ filter });
  });
  watch(paymentPeriod, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.paymentPeriodId = val.id;
    } else {
      delete filter.paymentPeriodId;
    }
    updateParams({ filter });
  });

  const resolveInvoiceAdjustmentTypeVariantAndIcon = (invoice) => {
    switch (+invoice) {
      case 1:
        return { variant: 'success', title: 'Đã phát hành' };
      case 3:
        return { variant: 'warning', title: 'Hóa đơn thay thế' };
      case 5:
        return { variant: 'warning', title: 'Hóa đơn điều chỉnh' };
      case 7:
        return { variant: 'danger', title: 'Hóa đơn hủy bỏ' };
      default:
        return { variant: 'info', title: 'Không xác định' }; // Xử lý trường hợp không khớp
    }
  };


  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytics,
    item,
    paymentItem,
    columns,
    rows,
    school,
    classObject,
    student,
    month,
    status,
    paymentStatus,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    approveStatus,
    dueDateStatus,
    paymentPeriod,
    fetchData,
    fetchReleaseInvoice,
    onEditItem,
    deleteReleaseInvoices,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    exportData,
    importData,
    downloadImportTemplate,
    resolveInvoiceAdjustmentTypeVariantAndIcon,
    resolveColWidthIfDisableBed,
    t
  };
}
