<template>
  <div class="income-expense-list-container">
    <b-row v-if="analytics" class="match-height">
      <b-col>
        <card-statistic title="Hóa đơn đã xuất" :value="Number(analytics.totalInvoice)" color="primary" />
      </b-col>
      <b-col>
        <card-statistic title="Hóa đơn hợp lệ" :value="Number(analytics.validInvoice)" color="secondary" />
      </b-col>
      <b-col>
        <card-statistic title="Hóa đơn đã hủy" :value="Number(analytics.cancelInvoice)" color="danger" />
      </b-col>
      <b-col>
        <card-statistic title="Tổng cộng" :value="Number(analytics.total).toLocaleString() + ' VNĐ'" color="success" />
      </b-col>
      <b-col>
        <card-statistic title="Tổng thuế VAT" :value="Number(analytics.taxValue).toLocaleString() + ' VNĐ'"
          color="warning" />
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t("Quản lý hóa đơn VAT") }}</b-card-title>
        <crud-buttons name="Thu/Chi" :selected-rows="selectedRows" modal="modal-income-expense" :show-import="false"
          :show-export="true" :hide-delete="true" :enable-add="false" @on-add="resetItem" @on-delete="onDelete"
          @on-export="exportData" @on-import-file="importData" @download-template-file="downloadImportTemplate" />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-school v-model="school" :label="null" />
          </b-col>
          <b-col>
            <select-class v-model="classObject" :school="school" />
          </b-col>
          <b-col>
            <select-student v-model="student" :school="school" :class-object="classObject" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-month v-model="month" :default-value="month" />
          </b-col>
          <b-col>
            <b-form-input v-model="searchTerm" placeholder="Tìm kiếm..." debounce="1000" />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table style-class="vgt-table striped bordered" mode="remote" :total-rows="totalRecords"
              :is-loading.sync="isLoading" :columns="columns" :rows="rows" :search-options="{
                enabled: false,
              }" :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
              @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange">
              <template slot="loadingContent">
                <b-spinner label="Loading" type="grow" />
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Code -->
                <span v-if="props.column.field === 'invoiceNo'">
                  <b-link v-b-modal.modal-release-invoice-preview class="font-weight-bold"
                    @click="onEditItem(props.row)">
                    {{ JSON.parse(props.row.viettelInvoiceResult).invoiceNo }}
                  </b-link>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <small class="text-muted"><br />{{ props.row.description }}</small>
                </span>

                <!-- Column: Apartment -->
                <span v-else-if="props.column.field === 'apartment'">
                  <span v-if="props.row.apartment">{{
                    props.row.apartment.name
                  }}</span>
                  <small v-if="props.row.room" class="text-muted">
                    <br />{{ props.row.room.name }}
                  </small>
                  <small v-if="props.row.bed" class="text-muted">
                    <br />{{ props.row.bed.name }}
                  </small>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'amount'">
                  <span class="text-primary">{{
                    Number(props.row.amount).toLocaleString()
                  }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'receiver'">
                  {{
                    props.row.isIncome ? props.row.payer : props.row.receiver
                  }}
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'tingee'">
                  {{
                    props.row.tingee
                      ? props.row.tingee.isCash
                        ? props.row.tingee.accountName
                        : props.row.tingee.accountNumber +
                        " - " +
                        props.row.tingee.vaAccountNumber
                      : ""
                  }}
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'dueDate'" class="flex">
                  <div>
                    <span>
                      {{ parseDateToString(props.row.dueDate) }}
                    </span>
                  </div>
                </span>

                <span v-else-if="props.column.field === 'month'">
                  <span>
                    {{ parseDateToString(props.row.month, "MM-YYYY") }}
                  </span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions name="Hóa đơn VAT" modal="modal-income-expense" :hide-print="false"
                    :disable-delete-reason="props.row.reservation && props.row.reservation.id > 0
                      ? 'Phiếu thu chi gắn với đặt cọc nên không thể xóa. Để xóa phiếu thu chi này bạn phải xóa đặt đặt cọc'
                      : null
                      " :hide-delete="!$can('delete', 'income-expense')" :hide-edit="true"
                    @on-delete="onDelete(props.row)" @on-edit="onEditItem(props.row)">
                    <template slot="other-buttons">
                      <b-button v-b-modal.modal-release-invoice-preview v-b-tooltip.hover.v-info variant="info"
                        class="btn-icon mr-50" title="Xem hóa đơn" size="sm" @click="onEditItem(props.row)">
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button v-if="
                        props.row.tingeeTransaction &&
                        props.row.tingeeTransaction.status === 'unknown' &&
                        $can('update', 'income-expense')
                      " v-b-tooltip.hover.v-success v-b-modal.modal-link-invoice variant="success"
                        class="btn-icon mr-50" :title="'Liên kết hóa đơn'" size="sm" @click="onEditItem(props.row)">
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>
                <span v-else-if="props.column.field === 'subTotal'">
                  {{ Number(props.row.subTotal).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'taxValue'">
                  {{ Number(Math.abs(props.row.taxValue)).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'total'">
                  {{ Number(props.row.total).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'codeOfTax'">
                  {{ JSON.parse(props.row.viettelInvoiceResult).codeOfTax }}
                </span>
                <span v-else-if="props.column.field === 'paymentStatus'">
                  <b-badge pill class="size-18" :variant="`light-${JSON.parse(props.row.viettelInvoiceResult).paymentStatus === 1
                    ? 'success'
                    : 'danger'
                    }`">{{
                      JSON.parse(props.row.viettelInvoiceResult).paymentStatus === 1
                        ? "Đã thanh toán"
                        : "Chưa thanh toán"
                    }}</b-badge>
                </span>
                <span v-else-if="props.column.field === 'adjustmentType'">
                  <b-badge pill class="size-18" :variant="`light-${resolveInvoiceAdjustmentTypeVariantAndIcon(
                    JSON.parse(props.row.viettelInvoiceResult).adjustmentType
                  ).variant
                    }`">{{
                      resolveInvoiceAdjustmentTypeVariantAndIcon(
                        JSON.parse(props.row.viettelInvoiceResult).adjustmentType
                      ).title
                    }}</b-badge>
                </span>
                <span v-else-if="props.column.field === 'issueDate'">
                  {{ parseDateToString(Number(JSON.parse(props.row.viettelInvoiceResult).issueDate)) }}
                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select v-model="serverParams.perPage" :options="['10', '20', '50', '100', '500']"
                      class="mx-1" @input="(value) =>
                        props.perPageChanged({ currentPerPage: value })
                        " />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.perPage" first-number
                      last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                        ">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">Không có bản ghi nào!</div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <income-expense-preview-modal ref="releaseInvoiceModal" :item="item" :fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  VBModal,
  VBTooltip,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
import SelectMonth from "@/views/components/SelectMonth.vue";
import SelectDate from "@/views/components/SelectDate.vue";
import CardStatistic from "@/views/components/CardStatistic.vue";
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from "@/auth/utils";
import SelectStudent from "@/views/components/SelectStudent.vue";
import SelectSchool from "../../../components/SelectSchool.vue";
import SelectClass from "../../../components/SelectClass.vue";
import IncomeExpensePreviewModal from "../preview/OutputInvoicePreviewModal.vue";
import SelectGeneralData from "@/views/components/SelectGeneralData.vue";
import useOutputInvoiceList from "./useOutputInvoiceList";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BFormInput,
    BButton,
    VueGoodTable,
    TableActions,
    CrudButtons,
    SelectDate,
    CardStatistic,
    SelectSchool,
    SelectClass,
    SelectStudent,
    IncomeExpensePreviewModal,
    SelectMonth,
    SelectGeneralData
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const {
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      school,
      classObject,
      student,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      paymentPeriod,
      fetchData,
      fetchReleaseInvoice,
      onEditItem,
      deleteReleaseInvoices,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      exportData,
      importData,
      downloadImportTemplate,
      resolveInvoiceAdjustmentTypeVariantAndIcon,
      resolveColWidthIfDisableBed,
      t
    } = useOutputInvoiceList();

    return {
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      school,
      classObject,
      student,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      paymentPeriod,
      fetchData,
      fetchReleaseInvoice,
      onEditItem,
      deleteReleaseInvoices,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      exportData,
      importData,
      downloadImportTemplate,
      resolveInvoiceAdjustmentTypeVariantAndIcon,
      resolveColWidthIfDisableBed,
      parseDateToString,
      t
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(releaseInvoice) {
      const deleteObjects =
        releaseInvoice && releaseInvoice.id > 0
          ? [releaseInvoice]
          : this.selectedRows;
      this.deleteReleaseInvoices(deleteObjects);
    },
    onApproveClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác DUYỆT phiếu thu/chi. Bạn có chắc chắn muốn xác nhận duyệt này không?`,
          {
            title: `Duyệt phiếu thu/chi`,
            okTitle: "Duyệt",
            cancelTitle: "Huỷ",
            okVariant: "primary",
            cancelVariant: "outline-secondary",
          }
        )
        .then((value) => {
          if (value) {
            this.approveIncomeExpense(invoices, true);
          }
        });
    },
    onDeclineClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác BỎ DUYỆT phiếu thu/chi. Bạn có chắc chắn muốn hủy duyệt không?`,
          {
            title: `Bỏ duyệt phiếu thu/chi`,
            okTitle: "Bỏ duyệt",
            cancelTitle: "Huỷ",
            okVariant: "warning",
            cancelVariant: "outline-secondary",
          }
        )
        .then((value) => {
          if (value) {
            this.approveIncomeExpense(invoices, false);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.income-expense-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
