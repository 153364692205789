export default {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  // Endpoints utils
  utilsPricingEndpoint: '/v1/utils/pricing',
  utilsCountriesEndpoint: '/v1/country',
  utilsProvinceEndpoint: '/v1/province',
  utilsDistrictEndpoint: '/v1/district',
  utilsWardEndpoint: '/v1/ward',
  utilsPaymentPeriodsEndpoint: '/v1/utils/payment-periods',
  utilsGendersEndpoint: '/v1/utils/genders',
  utilsUnitsEndpoint: '/v1/unit',
  utilsAbilitiesEndpoint: '/v1/permission',


  // Endpoints
  loginEndpoint: '/v1/auth/login',
  registerEndpoint: '/v1/auth/register',
  refreshEndpoint: '/v1/user/refresh-token',
  logoutEndpoint: '/v1/user/logout',
  forgotPasswordEndpoint: '/v1/auth/forgot-password',
  resetPasswordEndpoint: '/v1/auth/reset-password',
  firebaseTokenEndpoint: '/v1/auth/firebase-token',

  // Permission
  permissionListEndPoint: '/v1/permission',
  permissionGroupListEndPoint: '/v1/permission/group',

  userListByAdminEndpoint: '/v1/admin/users',
  userDetailByAdminEndpoint: '/v1/admin/user',
  updateSubscriptionByAdminEndpoint: '/v1/admin/user/subscription',
  changeUserPasswordByAdminEndPoint: '/v1/admin/user/reset-password',

  // Endpoint fee
  feeListEndpoint: '/v1/fee',
  feeCreateEndpoint: '/v1/fee',
  feeUpdateEndpoint: '/v1/fee',
  feeDetailEndpoint: '/v1/fee',
  feeDeleteEndpoint: '/v1/fee',

  // Endpoint fee type
  feeTypeListEndpoint: '/v1/fee-category',
  feeTypeCreateEndpoint: '/v1/fee-category',
  feeTypeDeleteEndpoint: '/v1/fee-category',
  feeTypeUpdateEndpoint: '/v1/fee-category',
  feeTypeDetailEndpoint: '/v1/fee-category',

  // report
  reportByServiceType: '/v1/report/report-by-fee-category',
  reportByService: '/v1/report/report-by-fee',
  reportRevenueSummary: '/v1/report/report-revenue-summary',

  // Endpoint school
  schoolListEndpoint: '/v1/school',
  schoolCreateEndpoint: '/v1/school',
  schoolUpdateEndpoint: '/v1/school',
  schoolDetailEndpoint: '/v1/school',
  schoolDeleteEndpoint: '/v1/school',
  schoolImportEndpoint: '/v1/school/import',
  schoolExportEndpoint: '/v1/school/export',
  schoolGenerateImportTemplateEndpoint: '/v1/school/generate-import-template',

  // Endpoint class
  classListEndpoint: '/v1/class',
  classCreateEndpoint: '/v1/class',
  classUpdateEndpoint: '/v1/class',
  classDetailEndpoint: '/v1/class',
  classDeleteEndpoint: '/v1/class',
  classImportEndpoint: '/v1/class/import',
  classExportEndpoint: '/v1/class/export',
  classGenerateImportTemplateEndpoint: '/v1/class/generate-import-template',

  // Endpoint student
  studentListEndpoint: '/v1/student',
  studentCreateEndpoint: '/v1/student',
  studentUpdateEndpoint: '/v1/student',
  studentDetailEndpoint: '/v1/student',
  studentDeleteEndpoint: '/v1/student',
  studentImportEndpoint: '/v1/student/import',
  studentExportEndpoint: '/v1/student/export',
  studentGenerateImportTemplateEndpoint: '/v1/student/generate-import-template',
  studentContactZaloListEndpoint: '/v1/student-contact-zalo',
  studentContactZaloDeleteEndpoint: '/v1/student-contact-zalo',


  // Endpoint invoice
  invoiceListEndpoint: '/v1/invoice',
  invoiceCreateEndpoint: '/v1/invoice',
  invoiceUpdateEndpoint: '/v1/invoice',
  invoiceUpdateTimeEndpoint: '/v1/invoice/update-time',
  invoiceDeleteEndpoint: '/v1/invoice',
  invoiceDetailEndpoint: '/v1/invoice',
  invoiceGetIncomeExpenseEndpoint: '/v1/invoice/income-expenses',
  invoiceApproveEndpoint: '/v1/invoice/approve',
  invoiceAnalyticsEndpoint: '/v1/invoice/analytics',
  invoiceCreatePaymentEndpoint: '/v1/invoice/create-payment',
  invoiceExportEndpoint: '/v1/invoice/export',
  invoiceExportZipEndpoint: '/v1/invoice/export-zip',
  invoiceExportZipPdfEndpoint: '/v1/invoice/export-zip-pdf',
  invoiceImportEndpoint: '/v1/invoice/import',
  invoiceHtmlEndpoint: '/v1/invoice/html',
  invoicePreviewEndpoint: '/v1/invoice/preview',
  invoicePreviewWithUUIDEndpoint: '/v1/invoice/preview-with-uuid',
  invoicePdfEndpoint: '/v1/invoice/pdf',
  invoiceViettelDraft: '/v1/viettel-invoice/draft',
  invoiceViettelIssue: '/v1/viettel-invoice/issue',
  invoiceImageEndpoint: '/v1/invoice/image',
  invoiceSearch: '/v1/invoice/search',
  invoiceSendViaZaloZNSEndpoint: '/v1/invoice/share-zalo-zns',
  invoiceSendViaZaloNormalEndpoint: '/v1/invoice/send-notifiy-via-zalo',
  invoiceSendViaZaloOAEndpoint: '/v1/invoice/send-notifiy-via-zalo-oa',
  invoiceSendViaAppEndpoint: '/v1/invoice/share-via-app',
  invoiceSendViaEmailEndpoint: '/v1/invoice/share-email',
  invoiceSendEndpoint: '/v1/invoice/send',
  invoiceGenerateMultipleEndpoint: '/v1/invoice/generate-multiple',
  invoiceGenerateImportTemplateEndpoint: '/v1/invoice/generate-import-template',
  invoiceParamsEndpoint: '/v1/invoice/params',

  remainPrepaidByStudent: '/v1/prepaid/remain-by-student',


  // Endpoint income expense
  incomeExpensePreviewEndpoint: '/v1/income-expense/preview',
  incomeExpensePreviewWithUUIDEndpoint: '/v1/income-expense/preview-with-uuid',
  incomeExpenseListEndpoint: '/v1/income-expense',
  incomeExpenseApproveEndpoint: '/v1/income-expense/approve',
  incomeExpenseCreateEndpoint: '/v1/income-expense',
  incomeExpenseUpdateEndpoint: '/v1/income-expense',
  incomeExpenseDeleteEndpoint: '/v1/income-expense',
  incomeExpenseAnalyticsEndpoint: '/v1/income-expense/analytics',
  incomeExpenseHTMLEndpoint: '/v1/income-expense/html',
  incomeExpenseDetailEndpoint: '/v1/income-expense',
  incomeExpenseExportEndpoint: '/v1/income-expense/export',
  incomeExpenseImportEndpoint: '/v1/income-expense/import',
  incomeExpenseGenerateImportTemplateEndpoint: '/v1/income-expense/generate-import-template',
  incomeExpenseCalculateAllocationEndpoint: '/v1/income-expense/calculate-allocation',
  incomeExpenseLinkInvoiceEndpoint: '/v1/income-expense/link-invoice',


  // Relase invoice
  releaseInvoiceListEndpoint: '/v1/viettel-invoice/release-invoices',
  releaseInvoicePreviewEndpoint: '/v1/viettel-invoice/release-invoices',
  releaseInvoiceCancelEndpoint: '/v1/viettel-invoice/release-invoices',
  releaseInvoiceUpdatePaymentStatusEndpoint: '/v1/viettel-invoice/release-invoices/update-invoice-status',
  releaseInvoiceCancelPaymentStatusEndpoint: '/v1/viettel-invoice/release-invoices/cancel-invoice-status',
  releaseInvoiceExportEndpoint: '/v1/viettel-invoice/release-invoices/export',
  releaseInvoiceAnalyticsEndpoint: '/v1/viettel-invoice/release-invoices/analysis',

  // Endpoint role
  roleListEndpoint: '/v1/role',
  roleDetailEndpoint: '/v1/role',
  roleAbilitiesEndpoint: '/v1/role/abilities',
  roleCreateEndpoint: '/v1/role',
  roleUpdateEndpoint: '/v1/role',
  roleDeleteEndpoint: '/v1/role',

  // Endpoint manager
  managerListEndpoint: '/v1/manager',
  managerDetailEndpoint: '/v1/manager',
  managerCreateEndpoint: '/v1/manager',
  managerUpdateEndpoint: '/v1/manager',
  managerDeleteEndpoint: '/v1/manager',


  // Endpoint notification
  notificationListEndpoint: '/v1/notification',
  notificationDetailEndpoint: '/v1/notification',
  notificationCreateEndpoint: '/v1/notification',
  notificationUpdateEndpoint: '/v1/notification',
  notificationDeleteEndpoint: '/v1/notification',

  // Endpoint system notification
  systemNotificationListEndpoint: '/v1/system-notification',
  systemNotificationMarkAsReadListEndpoint: '/v1/system-notification/mark-as-read',
  systemNotificationCountUnreadListEndpoint: '/v1/system-notification/count-unread',

  // Endpoint user
  userProfileEndpoint: '/v1/user/me',
  userProfileUpdateEndpoint: '/v1/user/update',
  userChangePasswordEndpoint: '/v1/user/change-password',
  userVerifyEmailEndpoint: '/v1/user/verify-email',
  userRequestVerifyEmailEndpoint: '/v1/user/request-verify-email',
  userRequestMissCallOTPEndpoint: '/v1/user/request-miss-call-otp',
  userRequestVerifyPhoneOTPEndpoint: '/v1/user/request-verify-phone',
  userResetPasswordWithOTPEndpoint: '/v1/user/reset-password-with-otp',
  userVerifyPhoneEndpoint: '/v1/user/verify-phone',
  userACLEndpoint: '/v1/user/acl',

  // Endpoint dashboard
  dashboardEndpoint: '/v1/dashboard/get-data',

  // Enpoint configuration
  userConfigurationEndpoint: '/v1/user-configuration',
  userConfigurationUpdateEndpoint: '/v1/user-configuration',
  emailConfigurationEndpoint: '/v1/email-configuration',
  emailConfigurationUpdateEndpoint: '/v1/email-configuration',
  apartmentConfigurationEndpoint: '/v1/apartment-configuration',
  apartmentConfigurationUpdateEndpoint: '/v1/apartment-configuration',

  // Report


  // Prepaid notification
  prepaidListEndpoint: '/v1/prepaid',
  prepaidExportEndpoint: '/v1/prepaid/export',
  prepaidCreateEndpoint: '/v1/prepaid',
  prepaidUpdateEndpoint: '/v1/prepaid',
  prepaidDeleteEndpoint: '/v1/prepaid',
  prepaidDetailByContractEndpoint: '/v1/prepaid/student',
  prepaidRemainByContractEndpoint: '/v1/prepaid/remain-by-contract',


  // admin
  adminListHost: '/v1/admin/hosts',
  adminListHostAnalytics: '/v1/admin/host-analytics',
  adminHostDetail: '/v1/admin/host',
  adminHostSubscription: '/v1/admin/host/subscription',
  adminHostSyncDataFromOldSystem: '/v1/admin/host/sync-data-from-old-system',
  adminHostChangePassword: '/v1/admin/host/reset-password',
  adminListOrder: '/v1/admin/orders',
  adminListOrderAnalytics: '/v1/admin/order-analytics',
  adminOrderDetail: '/v1/admin/order',
  adminListTenant: '/v1/admin/tenants',
  adminListTenantAnalytics: '/v1/admin/tenant-analytics',
  adminTenantDetail: '/v1/admin/tenant',

  // upload attachment
  attachmentUploadEndpoint: "/v1/attachment/upload",
  attachmentDeleteEndpoint: "/v1/attachment",

  // job
  jobEndPoint: "/v1/job",
  jobReRunEndPoint: "/v1/job/re-run",

  // system log
  systemLogEndPoint: "/v1/system-log",


  // bank
  bankListEndpoint: '/v1/bank',
  bankListTingeeEndpoint: '/v1/bank/tingee',


  // Endpoint invoice template
  invoiceTemplateListEndpoint: '/v1/template',
  invoiceTemplateCreateEndpoint: '/v1/template',
  invoiceTemplateUpdateEndpoint: '/v1/template',
  invoiceTemplatePreviewEndpoint: '/v1/template',
  invoiceTemplateDetailEndpoint: '/v1/template',
  invoiceTemplateDeleteEndpoint: '/v1/template',

  // Endpoint income expense template
  incomeExpenseTemplateListEndpoint: '/v1/income-expense-template',
  incomeExpenseTemplateCreateEndpoint: '/v1/income-expense-template',
  incomeExpenseTemplateUpdateEndpoint: '/v1/income-expense-template',
  incomeExpenseTemplatePreviewEndpoint: '/v1/income-expense-template',
  incomeExpenseTemplateDetailEndpoint: '/v1/income-expense-template',
  incomeExpenseTemplateDeleteEndpoint: '/v1/income-expense-template',

  // tingee
  tingeeVerifyOCBInfoEndpoint: '/v1/tingee/verify-ocb-info',
  tingeeConnectBankAccountEndpoint: '/v1/tingee/connect-bank-account',
  tingeeVerifyBankAccountEndpoint: '/v1/tingee/verify-bank-account',
  tingeeVerifyOCBInfoWithOtpEndpoint: '/v1/tingee/verify-ocb-info-with-otp',
  tingeeAccountEndpoint: '/v1/tingee',
  tingeeCreateVAAccountEndpoint: '/v1/tingee/ocb/create-va-coporation',
  tingeeDeleteAccountEndpoint: '/v1/tingee',
  tingeeApplyOneTimeQrcodeEndpoint: '/v1/tingee/apply-one-time-qrcode',

  // Endpoint general data
  generalDataListEndpoint: '/v1/general-data',
  generalDataCreateEndpoint: '/v1/general-data',
  generalDataUpdateEndpoint: '/v1/general-data',
  generalDataDetailEndpoint: '/v1/general-data',
  generalDataDeleteEndpoint: '/v1/general-data',

  // Endpoint zalo
  zaloListEndpoint: '/v1/zalo',
  zaloCreateEndpoint: '/v1/zalo',
  zaloUpdateEndpoint: '/v1/zalo',
  zaloDetailEndpoint: '/v1/zalo',
  zaloDeleteEndpoint: '/v1/zalo',
  zaloLinkURLEndpoint: '/v1/zalo/link-oa-url',
  zaloLinkOAEndpoint: '/v1/zalo/link-oa',

};
