<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal id="modal-release-invoice-preview" ref="refModal" title="Xem hóa đơn" hide-footer size="lg" scrollable
    cancel-variant="outline-secondary" no-close-on-backdrop @show="showModal" @hidden="resetModal">
    <!-- Body -->
    <div v-if="!isLoading" class="mt-50">
      <b-button variant="primary" class="ml-50" @click="cancelPaymentStatus">
        Cập nhật kê khai thuế
      </b-button>
      <b-button v-if="!isHidden" variant="warning" class="ml-50" @click="updatePaymentStatus">
        Cập nhật trạng thái thanh toán
      </b-button>
      <b-button v-if="isHidden" variant="danger" class="ml-50" @click="cancelPaymentStatus">
        Hủy trạng thái thanh toán
      </b-button>
    </div>

    <div class="d-flex justify-content-center my-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <iframe v-if="!isLoading" :src="viewDraftInvoice == true && draftInvoice
      ? `data:application/pdf;base64,${draftInvoice}`
      : pdfUrl
      " style="width: 100%; height: 80vh; border: none" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import { required } from "@validations";
import useReleaseInvoicePreviewModal from "./useOutputInvoicePreviewModal";

export default {
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    fetchData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      showModal,
      getViettelInvoiceDraft,
      pdfUrl,
      isLoading,
      previewUrl,
      draftInvoice,
      viewDraftInvoice,
      isHidden,
      cancelPaymentStatus,
      updatePaymentStatus,
    } = useReleaseInvoicePreviewModal(props);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      showModal,
      getViettelInvoiceDraft,
      isHidden,
      pdfUrl,
      isLoading,
      previewUrl,
      draftInvoice,
      viewDraftInvoice,
      cancelPaymentStatus,
      updatePaymentStatus,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }

    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
