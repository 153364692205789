export default [
  {
    path: '/cashbook/list',
    name: 'cashbook-list',
    component: () => import('@/views/finance/cashbook/list/CashbookList.vue'),
    meta: {
      resource: 'cashbook',
      action: 'read',
    },
  },
  {
    path: '/invoice/list',
    name: 'invoice-list',
    component: () => import('@/views/finance/invoice/list/InvoiceList.vue'),
    meta: {
      resource: 'invoice',
      action: 'read',
    },
  },
  {
    path: '/income-expense/list',
    name: 'income-expense-list',
    component: () => import('@/views/finance/income_expense/list/IncomeExpenseList.vue'),
    meta: {
      resource: 'income-expense',
      action: 'read',
    },
  },
  {
    path: '/prepaid/list',
    name: 'prepaid-list',
    component: () => import('@/views/finance/prepaid/list/PrepaidList.vue'),
    meta: {
      resource: 'prepaid',
      action: 'read',
    },
  },
  {
    path: '/release-invoice/list',
    name: 'release-invoice-list',
    component: () => import('@/views/finance/release_invoice/list/ReleaseInvoiceList.vue'),
    meta: {
      resource: 'release-invoice',
      action: 'read',
    },
  },
  {
    path: '/output-invoice/list',
    name: 'output-invoice-list',
    component: () => import('@/views/finance/output_invoice/list/OutputInvoiceList.vue'),
    meta: {
      resource: 'output-invoice',
      action: 'read',
    },
  }
];
